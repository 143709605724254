import { render, staticRenderFns } from "./RegionsFilter.vue?vue&type=template&id=58a07046&scoped=true"
import script from "./RegionsFilter.vue?vue&type=script&lang=js"
export * from "./RegionsFilter.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58a07046",
  null
  
)

export default component.exports