<template>
    <Select
        v-model="selectedRegions"
        :get-data-vuex="getFilteredRegions"
        :required="required"
        class="white"
        :searchable="false"
        :placeholder="placeholder"
        :multiple="multiple">
    </Select>
</template>

<script>
  import Select from "@/components/Common/Select/Select.vue";
  import { StatisticFilters } from "@core/mixins/statistic/statisticFilters";
  import { mapState } from "vuex";

  export default {
    name: "RegionsFilter",
    mixins: [
      StatisticFilters
    ],
    components: { Select },
    props: {
      value: {
        type: [Array, String, null],
        default: null
      },

      required: {
        type: Boolean,
        default: false
      },

      multiple: {
        type: Boolean,
        default: false
      },

      placeholder: {
        type: String,
        default: ""
      }
    },
    data () {
      return {
        namespace: "admin/regions"
      };
    },
    computed: {
      ...mapState("admin/regions/regionsFilters", {
        regions: state => state.data
      }),

      selectedRegions: {
        get () {
          return this.value;
        },
        set (value) {
          this.$emit("input", value);
        }
      }
    },
    methods: {
      async getFilteredRegions () {
        await this.getFiltersByName("regionsFilters", this.regions, this.$route.query.regionId, "regionId");
  
        return this.regions;
      }
    }
  };
</script>

<style scoped>

</style>
